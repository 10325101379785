import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0,3,2];

export const dictionary = {
		"/": [5],
		"/kiosk/[kiosk]": [~8],
		"/kiosk/[kiosk]/plate": [~9],
		"/location/[lookup]": [10,[3]],
		"/location/[lookup]/plate": [~11,[3]],
		"/sentry-example": [12],
		"/support/code": [~13,[4]],
		"/support/confirmation": [14,[4]],
		"/support/confirmation/[number]": [~15,[4]],
		"/[location]": [6,[2]],
		"/[location]/[tenant]": [~7,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';